<template>
  <v-row class="mb-5">
    <v-col sm="12" md="12" lg="12" class="p-5 results-box box-shadow">
      <v-chip v-if="badge" color="teal accent-4" class="badge" label dark>{{ badge }}</v-chip>

      <v-row class="mt-5">
        <v-col sm="12" md="12" lg="3" cols="12" class="results-box-column ml-0 pl-0 mr-0 pr-0 text-center">
          
          <v-img v-if="this.logo" :alt="getProviderName()" :src="this.logo" max-width="200" width="200" style="margin: auto;" eager />
          <p v-else>{{ getProviderName() }}</p>
          <p v-if="rating.has_rating" class="mt-3 mb-0">
            <v-rating v-model="rating.value" :length="rating.length" :empty-icon="rating.empty_icon" :half-icon="rating.half_icon" :full-icon="rating.full_icon" color="yellow darken-4" background-color="white" size="20" dense readonly half-increments></v-rating>
          </p>
        </v-col>
        <v-col sm="6" md="6" lg="3" cols="12" class="results-box-column"><span class="results-box-column-heading">Lånebelopp</span> {{ formatCurrency(this.searchResult.amount_minimum) }} - {{ formatCurrency(this.searchResult.amount_maximum) }} kr</v-col>
        <!-- <v-col sm="12" md="12" lg="2" class="results-box-column"><span class="results-box-column-heading">Löptid</span> 1 mån - 12 år</v-col> -->
        <v-col sm="6" md="6" lg="3" cols="12" class="results-box-column">
          <v-tooltip color="black" max-width="400" top>
            <template v-slot:activator="{ on, attrs }">
              <span class="results-box-column-heading" v-bind="attrs" v-on="on">
                Nominell ränta <v-icon small color="black" style="font-size: 13px">fas fa-question-circle</v-icon>
              </span>
            </template>
            Den nominella räntan bestämmer den kostnad du skall betala på ditt lån. Räntan innehåller inte övriga fasta avgifter som kan tillkomma. Räntan är individuell och bestäms efter ansökan och kreditbedömning.
          </v-tooltip>
          {{ formatPercent(this.searchResult.interest_minimum) }}% - {{ formatPercent(this.searchResult.interest_maximum) }}%
        </v-col>
        <v-col sm="12" md="12" lg="3" cols="12" class="results-box-column mr-0 pr-0 text-center">
          <p>
            <v-btn v-on:click="openLink()" type="submit" color="orange darken-2" dark large class="w-100 p-3 font-weight-bold apply-button">
              Ansök nu <v-icon small class="ml-2">fas fa-arrow-circle-right</v-icon>
            </v-btn>
          </p>
          <p>
            <v-btn v-on:click="openLink()" type="submit" color="black" dark large outlined class="w-100 p-2 font-weight-bold more-info-button">
              Mer info
            </v-btn>
          </p>
          <p v-if="getReviewLink()" class="review-link mt-2"><a :href="getReviewLink()">Läs recension</a></p>
        </v-col>
      </v-row>
      <v-row class="results-box-usp">
        <v-col>
          <ul>
            <li v-for="usp in createUSPList(this.searchResult)" :key="usp">
              <v-icon small color="green" class="mr-2">fas fa-check</v-icon> {{ usp }}
            </li>
          </ul>
        </v-col>
      </v-row>
      <v-expansion-panels class="more-information" flat accordion>
        <v-expansion-panel>
          <v-expansion-panel-header class="accordion-header" expand-icon="fas fa-chevron-down">Mer information</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-container fluid>
              <v-row class="mt-0">
                <v-col sm="12" lg="3" cols="12">
                  <span class="span">Företag</span>
                  <p>{{ getProviderLegalName() }}</p>
                </v-col>
                <v-col sm="12" lg="3" cols="12">
                  <span>UC</span>
                  <p v-if="getProviderCreditReportProvider() === 'uc'"><v-chip dense label color="red" text-color="white"><v-icon small color="white" class="mr-2" style="font-size: 13px">fas fa-exclamation-circle</v-icon> Ja</v-chip></p>
                  <p v-else><v-chip dense label color="green" text-color="white"><v-icon small color="white" class="mr-2" style="font-size: 13px">fas fa-check</v-icon> Nej ({{ getProviderCreditReportProvider() }})</v-chip></p>
                </v-col>
                <v-col sm="12" lg="3" cols="12">
                  <span>Betalningsanmärkningar</span>
                  <p v-if="this.searchResult.bad_credit"><v-chip dense label color="green" text-color="white"><v-icon small color="white" class="mr-2" style="font-size: 13px">fas fa-check</v-icon> Accepteras</v-chip></p>
                  <p v-else><v-chip dense label color="red" text-color="white"><v-icon small color="white" class="mr-2" style="font-size: 13px">fas fa-exclamation-circle</v-icon> Accepteras ej</v-chip></p>
                </v-col>
                <v-col sm="12" lg="3" cols="12">
                  <span>Löptid</span>
                  <p>{{ this.searchResult.period_years_minimum }} - {{ this.searchResult.period_years_maximum }} år</p>
                </v-col>
                <v-col sm="6" lg="3" cols="6">
                  <span>Lånebelopp</span>
                  <p>{{ formatCurrency(this.searchResult.amount_minimum) }} - {{ formatCurrency(this.searchResult.amount_maximum) }} kr</p>
                </v-col>
                <v-col sm="6" lg="3" cols="6">
                  <span>Uppläggningsavgift</span>
                  <p>{{ formatCurrency(this.searchResult.setup_fee) }} kr</p>
                </v-col>
                <v-col sm="6" lg="3" cols="6">
                  <span>Uttagsavgift</span>
                  <p>{{ formatCurrency(this.searchResult.withdrawal_fee) }} kr</p>
                </v-col>
                <v-col sm="6" lg="3" cols="6">
                  <span>Aviavgift</span>
                  <p>{{ formatCurrency(this.searchResult.invoice_fee) }} kr</p>
                </v-col>
                <v-col sm="6" lg="3" cols="6">
                  <span>Nominell årsränta</span>
                  <p>{{ this.searchResult.interest_minimum }}% - {{ this.searchResult.interest_maximum }}%</p>
                </v-col>
                <v-col sm="6" lg="3" cols="6">
                  <span>Lägsta ålder</span>
                  <p>{{ this.searchResult.minimum_age }} år</p>
                </v-col>
                <v-col sm="6" lg="6" cols="6">
                  <span>Lägsta årsinkomst</span>
                  <p>{{ formatCurrency(this.searchResult.minimum_yearly_salary) }} kr</p>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-row>
        <v-col class="example">
          <p><small><strong>Räkneexempel:</strong> {{ generateExampleCalculation() }}</small></p>
        </v-col>
      </v-row>


    </v-col>
  </v-row>
</template>

<script>
import * as numbersUtils from '@/utils/numbers'

export default {
  name: 'PersonalLoansResultSingle',

  props: {
    searchResult: { type: Object, required: true }
  },

  components: {
    
  },

  data: () => ({
    link: "",
    logo: null,
    badge: null,
    rating: {
      value: 3.1,
      empty_icon: "$ratingEmpty",
      half_icon: "fas fa-star-half",
      full_icon: "fas fa-star"
    }
  }),

  beforeMount ()
  {
    this.setInitialValues()
  },

  updated ()
  {
    this.setInitialValues()
  },

  methods: {
    formatCurrency: numbersUtils.formatCurrency,
    formatPercent: numbersUtils.formatPercent,

    setInitialValues()
    {
      if (this.searchResult) {
        this.link = this.buildOutgoingLink(this.searchResult.provider, this.searchResult.service_type)
      }

      this.setProviderLogoUrl()

      this.setRating()

      this.setBadge()
    },

    setRating ()
    {
      if (this.searchResult.providerData.rating) {
        this.rating.has_rating = true
        this.rating.value = parseInt(this.searchResult.providerData.rating)
      } else {
        this.rating.has_rating = false
      }
    },

    setBadge ()
    {
      if (this.searchResult.providerData.badge) {
        this.badge = this.searchResult.providerData.badge
      }
    },

    openLink ()
    {
      window.open(this.link, "_blank")
    },

    getProductData ()
    {
      return this.searchResult
    },

    getProviderName ()
    {
      return this.searchResult.providerData.name
    },

    getProviderLegalName ()
    {
      return this.searchResult.providerData.legal_name
    },

    getProviderCreditReportProvider ()
    {
      return this.searchResult.providerData.credit_report_provider
    },

    setProviderLogoUrl ()
    {
      if (this.searchResult.providerData.logo_filename) {
        this.logo = require('@/assets/logos/' + this.searchResult.providerData.logo_filename)
      }
    },

    buildOutgoingLink (providerId, serviceType)
    {
      return this.$parent.$parent.buildOutgoingLink(providerId, serviceType)
    },

    getReviewLink ()
    {
      return this.searchResult.providerData.review_link
    },

    createUSPList (loanData)
    {
      // const providerData = this.getProviderDataById(loanData.loan_provider)
      let USPs = []

      // Does provider accept remarks?
      if (loanData.bad_credit === true) {
        USPs.push('Accepterar betalningsanmärkning')
      }

      // Does provider have zero fees?
      if ((loanData.setup_fee + loanData.withdrawal_fee + loanData.invoice_fee) === 0) {
        USPs.push('Inga fasta avgifter')
      }

      // Does provider use UC?
      if (loanData.providerData.credit_report_provider !== 'uc') {
        USPs.push('Ingen UC')
      }

      // Append the manual USPs
      const combinedUsps = [].concat(USPs, loanData.providerData.usp)

      return combinedUsps
    },

    generateExampleCalculation ()
    {
      return this.$parent.$parent.generateExampleCalculation(this.searchResult.example_calculation)
    },
  }

}
</script>

<style>

</style>