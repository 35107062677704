<template>
<div>
  <v-container class="mt-0 ml-0 mr-0 mb-5 pa-5 slider-border" fluid>
    <v-row class="mb-4 pl-3 pr-3">
      <v-col sm="12" md="4" lg="4" cols="12" class="ma-0 pa-0 mb-2">
        <strong>Lånebelopp</strong> <br/> <span style="font-size: 24px; font-weight: bold; color: #25b5b4">{{ formatCurrency($parent.loanTotals.amount_minimum) }} - {{ formatCurrency($parent.loanTotals.amount_maximum) }} kr</span>
      </v-col>
      <v-col sm="12" md="4" lg="4" cols="12" class="ma-0 pa-0 mb-2">
        <strong>Ränta</strong> <br/> <span style="font-size: 24px; font-weight: bold; color: #25b5b4">{{ formatPercent($parent.loanTotals.interest_minimum) }}% - {{ formatPercent($parent.loanTotals.interest_maximum) }}%</span>
      </v-col>
      <v-col sm="12" md="4" lg="4" cols="12" class="ma-0 pa-0 mb-2">
        <strong>Antal långivare</strong> <br/> <span style="font-size: 24px; font-weight: bold; color: #25b5b4">{{ $parent.loanTotals.amount_of_lenders }} st</span>
      </v-col>
    </v-row>
    <v-row class="d-flex">

      <v-col sm="12" md="3" lg="3" cols="12" class="order-0 order-sm-0 order-md-0 order-lg-0 ma-0 mt-5 mb-3 pa-0 pl-3 pr-3">
        <div>
          <strong>Ange lånebelopp</strong>
          <v-text-field label="Lånebelopp" placeholder="Välj lånebelopp" type="number" suffix="kr" outlined single-line v-model="form.loan_amount" style="font-size: 130%; font-weight: bold; width: 100%; max-width: 400px; margin-top: 10px;" hide-details @change="setSlider(form.loan_amount)" @click="clearField()"></v-text-field>
          <small>Lånebelopp från {{ formatCurrency($parent.loanTotals.amount_minimum) }} kr till {{ formatCurrency($parent.loanTotals.amount_maximum) }} kr.</small>
        </div>
      </v-col>

      <v-col v-if="$parent.show_period_select" sm="12" md="3" lg="3" cols="12" class="order-2 order-sm-2 order-md-1 order-lg-1 ma-0 mt-5 pa-0 pl-3 pr-3">
        <div>
          <strong>Ange löptid</strong>
          <v-select v-model="selectedPeriod" :items="form.loanPeriodOptions" outlined style="font-size: 130%; font-weight: bold; width: 100%; max-width: 400px; margin-top: 10px;" hide-details></v-select>
          <small>Löptid från 1 månad - {{ $parent.loanTotals.period_years_maximum }} år</small>
        </div>
      </v-col>

      <v-col v-if="$parent.show_direct_payment_select" sm="12" md="3" lg="3" cols="12" class="order-2 order-sm-2 order-md-1 order-lg-1 ma-0 mt-5 pa-0 pl-3 pr-3">
        <div>
          <strong>Direktbetalning till bank</strong>
          <v-select v-model="selectedDirectPaymentOption" :items="form.directPaymentOptions" outlined style="font-size: 130%; font-weight: bold; width: 100%; max-width: 400px; margin-top: 10px;" hide-details></v-select>
          <small>Välj din bank för att filtrera direktbetalning</small>
        </div>
      </v-col>

      <v-col sm="12" md="12" lg="12" cols="12" style="flex-basis: 100%" class="order-1 order-sm-1 order-md-2 order-lg-2 ma-0 pa-0 pl-3 pr-3">
        <v-slider
          v-model="slider"
          class="ma-0 pa-0"
          :min="amountMinimum"
          :max="amount_maximum"
          :step="incrementValue"
          color="#25b5b4"
          track-color="#eeeeee"
          height="50"
          :ripple="checkbox.ripple"
          style="max-width: 100%"
          hide-details
        >
        </v-slider>
      </v-col>
      <v-col class="order-1 order-sm-1 order-md-2 order-lg-2 pt-0 text-left">
        <small style="margin-left: 0px !important;">{{ formatCurrency($parent.loanTotals.amount_minimum) }} kr</small>
      </v-col>
      <v-col class="order-1 order-sm-1 order-md-2 order-lg-2 pt-0 text-right">
        <small style="margin-right: 0 !important;">{{ formatCurrency($parent.loanTotals.amount_maximum) }} kr</small>
      </v-col>
    </v-row>
    <v-row class="pl-3 pr-3 pb-0 mt-5">
      <v-col class="ma-0 mb-3 pa-0" sm="12" md="12" lg="4" cols="12">
        <v-checkbox v-model="filter.bad_credit" label="Accepterar betalningsanmärkningar" color="#25b5b4" on-icon="fa-check-square" off-icon="far fa-square" :ripple="checkbox.ripple" @change="$parent.setFilterBadCredit(filter.bad_credit)" class="ma-0 pa-0" hide-details></v-checkbox>
      </v-col>
      <v-col class="ma-0 mb-3 pa-0" sm="12" md="12" lg="4" cols="12">
        <v-checkbox v-model="filter.no_uc" label="Ingen UC" on-icon="fa-check-square" color="#25b5b4" off-icon="far fa-square" :ripple="checkbox.ripple" @change="$parent.setFilterNoUC(filter.no_uc)" class="ma-0 pa-0" hide-details></v-checkbox>
      </v-col>
    </v-row>
</v-container>
  <v-container class="mt-0 ml-0 mr-0 mb-1 pt-0 pb-0 pr-3 pl-3" fluid>
    <v-row>
      <v-col class="ma-0 pa-0">
        <v-icon small class="mr-1" style="color: #000">fas fa-search</v-icon> <strong style="font-size: 90%">Visar {{ $parent.filteredResultsCount }} av {{ $parent.searchResults.length }} st</strong>
      </v-col>
    </v-row>
  </v-container>
</div>
</template>

<script>
import * as numbersUtils from '@/utils/numbers'

export default {

  props: {
    amount_minimum: { type: Number, required: true },
    amount_maximum: { type: Number, required: true },
    loan_amount: { type: Number, required: true },
    period_years: { type: Number, required: false },
    bad_credit: { type: Boolean, required: true },
    no_uc: { type: Boolean, required: true }
  },

  data: () => ({
    slider: 1000,
    selectedPeriod: 4,
    selectedDirectPaymentOption: "all",
    form: {
      loan_amount: null,
      loanPeriodOptions: [
        {
          value: 0,
          text: '1 månad',
          months: 1
        },
        {
          value: 1,
          text: '2 månader',
          months: 2
        },
        {
          value: 2,
          text: '3 månader',
          months: 3
        },
        {
          value: 3,
          text: '6 månader',
          months: 6
        },
        {
          value: 4,
          text: '1 år',
          months: 12
        },
        {
          value: 5,
          text: '2 år',
          months: 24
        },
        {
          value: 6,
          text: '3 år',
          months: 36
        },
        {
          value: 7,
          text: '4 år',
          months: 48
        },
        {
          value: 8,
          text: '5 år',
          months: 60
        },
        {
          value: 9,
          text: '6 år',
          months: 72
        },
        {
          value: 10,
          text: '12 år',
          months: 144
        },
        {
          value: 11,
          text: '15 år',
          months: 180
        },
        {
          value: 12,
          text: '20 år',
          months: 240
        }
      ],
      directPaymentOptions: [
        {
          value: 'all',
          text: 'Alla banker'
        },
        {
          value: 'seb',
          text: 'SEB'
        },
        {
          value: 'nordea',
          text: 'Nordea'
        },
        {
          value: 'handelsbanken',
          text: 'Handelsbanken'
        },
        {
          value: 'danskebank',
          text: 'Danske Bank'
        },
        /*
        {
          value: 'lansforsakringarbank',
          text: 'Länsförsäkringar Bank'
        },
        {
          value: 'icabanken',
          text: 'ICA Banken'
        }
        */
      ]
    },
    filter: {
      bad_credit: false,
      no_uc: false,
    },
    checkbox: {
      ripple: false
    }
  }),

  watch: {
    slider: function (val)
    {
      this.form.loan_amount = parseInt(val)
      this.$parent.setFilterAmount(val)
    },

    selectedPeriod: function (val)
    {
      const periodYears = this.getPeriodInYearsFromOptions(val)
      this.$parent.filters.period_years = periodYears
    },

    selectedDirectPaymentOption: function (val)
    {
      this.$parent.filters.direct_payment_bank = val
    }
  },

  beforeMount () {
    this.slider = parseInt(this.loan_amount)
    this.form.loan_amount = parseInt(this.loan_amount)
    this.selectedPeriod = this.getPeriodYearsIndexFromYears(this.period_years)
    this.filter.bad_credit = this.bad_credit
    this.filter.no_uc = this.no_uc
  },

  computed: {
    // Increase increment value the higher the selected value
    incrementValue ()
    {
      if (this.slider <= 10000) {
        return 100
      } else if (this.slider <= 100000) {
        return 1000
      } else if (this.slider > 100000 && this.slider <= 1000000) {
        return 10000
      } else {
        return 100000
      }
    },

    // We need to adjust the minimum value of the slider to 0 when crossing 100 000 or a bug will calculate it incorrectly and take the original minimum into account
    amountMinimum ()
    {
      // if (this.form.loan_amount === null) {
      //   return null
      // } else if (this.form.loan_amount >= 1 && this.form.loan_amount <= 100000) {
      //   return 1000
      // } else {
      //   return null
      // }
      return this.form.loan_amount <= 100000 ? this.amount_minimum : 0
    }
  },
  
  methods: {
    formatCurrency: numbersUtils.formatCurrency,
    formatPercent: numbersUtils.formatPercent,

    setSlider (amount)
    {
      if (!amount) {
        this.form.loan_amount = this.slider
      } else {
        this.slider = parseInt(amount)
      }
    },

    clearField ()
    {
      this.form.loan_amount = null
    },

    getPeriodInYearsFromOptions (value)
    {
      const selectedPeriodOption = this.form.loanPeriodOptions.find(o => o.value === value)
      const periodInYears = selectedPeriodOption.months / 12

      return periodInYears
    },

    getPeriodYearsIndexFromYears (years)
    {
      const months = years * 12
      const selectedPeriod = this.form.loanPeriodOptions.find(o => o.months === months)

      if (typeof selectedPeriod === 'undefined') {
        const oneYear = this.form.loanPeriodOptions.find(o => o.months === 12)
        return oneYear.value
      } else {
        return selectedPeriod.value
      }
      
    }
  }
}
</script>

<style>
.v-slider__track-container {
  height: 10px !important;
}

.v-slider__track {
  height: 10px !important;
}

.v-slider__thumb {
  width: 35px !important;
  height: 35px !important;
  left: -15px !important;
}

.v-slider__thumb:before {
  content: none !important;
}

.v-slider--horizontal {
  margin: 0 !important;
}

.slider-border {
  border: solid 1px #d0d0d0;
  border-radius: 5px;
}
</style>
