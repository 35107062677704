<template>
  <v-row class="mb-5">
    <v-col sm="12" md="12" lg="12" class="p-5 results-box box-shadow">
      NoServiceTypeResultSingle
    </v-col>
  </v-row>
</template>

<script>
import * as numbersUtils from '@/utils/numbers'

export default {
  name: 'NoServiceTypeResultSingle',

  props: {
    searchResult: { type: Object, required: true }
  },

  components: {
    
  },

  data: () => ({
    link: "",
    rating: {
      value: 3.1,
      empty_icon: "$ratingEmpty",
      half_icon: "fas fa-star-half",
      full_icon: "fas fa-star"
    }
  }),

  beforeMount ()
  {
    if (this.searchResult) {
      this.link = this.formatOutgoingLink(this.searchResult.provider, 'account_credit_loans')
    }   
  },

  methods: {
    formatCurrency: numbersUtils.formatCurrency,

    openLink ()
    {
      window.open(this.link, "_blank")
    },

    getProductData ()
    {
      return this.searchResult
    },

    getProviderName ()
    {
      return this.searchResult.providerData.name
    },

    getProviderLegalName ()
    {
      return this.searchResult.providerData.legal_name
    },

    getProviderCreditReportProvider ()
    {
      return this.searchResult.providerData.credit_report_provider
    },

    assertProviderLogoExists ()
    {
      if (!this.searchResult.providerData.logo_filename) {
        return false
      }

      const file = require('@/assets/' + this.searchResult.providerData.logo_filename)

      if (file) {
        return true
      } else {
        return false
      }
    },

    getProviderLogoUrl ()
    {

      if (!this.searchResult.providerData.logo_filename) {
        return null
      }

      return require('@/assets/' + this.searchResult.providerData.logo_filename)
    },

    formatOutgoingLink (providerId, serviceType)
    {
      return '/r/?service_type=' + serviceType + '&provider_id=' + providerId
    },

    createUSPList (loanData)
    {
      // const providerData = this.getProviderDataById(loanData.loan_provider)
      const USPs = []

      // Does provider accept remarks?
      if (loanData.accept_remark === true) {
        USPs.push('Accepterar betalningsanmärkning')
      }

      // Does provider have zero fees?
      if ((loanData.setup_fee + loanData.withdrawal_fee + loanData.invoice_fee) === 0) {
        USPs.push('Inga fasta avgifter')
      }

      // Does provider take UC?
      if (this.searchResult.providerData.credit_report_provider !== 'uc') {
        USPs.push('Ingen UC')
      }

      // Append the manual USPs

      return USPs
    }
  }

}
</script>

<style>
.results-box {
  background: #ffffff;
}

.results-box-column {
  color: #000;
  font-size: 110%;
  font-weight: bold;
  margin-bottom: 10px;
}

.results-box-column-heading {
  color: #737373;
  text-transform: uppercase;
  display: block;
  font-size: 80%;
  font-weight: bold;
}

.results-box-usp {
  border-top: solid 1px #d0d0d0;
}

.results-box-usp ul {
  display: table;
  margin: 0 auto;
  padding-left: 0 !important;
}

.results-box-usp ul li {
  list-style: none;
  display: list-item;
  float: left;
  padding: 15px;
  font-size: 90%;
}

.more-information {
  font-size: 80%;
}

.more-information span {
  font-weight: bold;
}

.more-information p {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.v-application .v-expansion-panel-header {
  text-align: center !important;
  display: block;
}

.v-application .v-expansion-panel-header__icon {
  margin-left: unset !important;
}

.accordion-header i {
  font-size: 15px !important;
  margin-left: 10px;
}

.v-chip__content {
  margin: 0 !important;
  font-size: 90%;
}

.apply-button {
  color: #ffffff !important;
  font-size: 110% !important;
  height: 60px !important;
  width: 200px !important;
}

.apply-button a, .review-link a:visited, .review-link a:hover, .review-link a:active {
  color: #ffffff !important;
  text-decoration: none;
}

.review-link a, .review-link a:visited, .review-link a:hover, .review-link a:active {
  font-size: 90%;
  color: #25b5b4 !important;
  text-decoration: none;
}
</style>