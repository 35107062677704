<template>
  <v-container class="pa-0" fluid>
    <Slider v-if="loadingComplete && widgetParams.slider === 'true' && !this.widgetParams.provider_id" :amount_minimum="this.loanTotals.amount_minimum" :amount_maximum="this.loanTotals.amount_maximum" :loan_amount="this.filters.loan_amount" :period_years="this.filters.period_years" :bad_credit="this.filters.accept_bad_credit" :no_uc="this.filters.no_uc" />
    <LoanResult v-if="loadingComplete && filteredResults.length >= 1" :widgetParams="widgetParams" :searchResults="searchResults" :filteredResults="filteredResults" />
    <v-container fluid><v-row v-if="filteredResults.length <= 0" class="text-center"><v-col sm="12" md="12" lg="12" cols="12">Inga lån matchade sökningen. Vänligen ändra sökkriterier.</v-col></v-row></v-container>
  </v-container>
</template>

<script>
import * as numbersUtils from '@/utils/numbers'
import { sortBy } from 'lodash'

import Providers from '@/assets/data/providers.json'

import Slider from './Slider.vue'
import LoanResult from './LoanResults/LoanResult.vue'

export default {
  name: 'LoanService',

  components: {
    Slider, LoanResult
  },

  props: {
    queryParams: { type: URLSearchParams, required: true },
    widgetParams: { type: Object, required: true }
  },

  data: () => ({
    loadingComplete: false,
    Providers,
    providerData: [],
    AccountCreditLoansData: [],
    PaydayLoansData: [],
    PersonalLoansData: [],
    BrokerLoansData: [],
    searchResults: [],
    show_period_select: false,
    show_direct_payment_select: false,
    loanTotals: {
      amount_minimum: null,
      amount_maximum: null,
      interest_minimum: null,
      interest_maximum: null,
      period_years_minimum: null,
      period_years_maximum: null,
      amount_of_lenders: null
    },
    filters: {
      loan_amount: 10000,
      period_years: 1,
      limit: 5,
      direct_payment_bank: 'all',
      accept_bad_credit: false,
      no_uc: false
    }
  }),

  beforeMount () {
    try {
      if (this.widgetParams.provider_id) {
        const providerIds = this.setProviderIdsFromParams(this.widgetParams.provider_id)

        providerIds.forEach(id => {
          this.providerData.push(this.getProviderDataById(id))
        })
      }

      if (this.widgetParams.bad_credit) {
        this.filters.accept_bad_credit = true
      }

      if (this.widgetParams.no_uc) {
        this.filters.no_uc = true
      }

      if (this.widgetParams.loan_amount) {
        this.filters.loan_amount = this.widgetParams.loan_amount
      }

      if (this.widgetParams.period_years) {
        this.filters.period_years = parseInt(this.widgetParams.period_years)
      }

      if (this.queryParams.get('loan_amount')) {
        this.filters.loan_amount = parseInt(this.queryParams.get('loan_amount'))
      }

      if (this.queryParams.get('period_years')) {
        this.filters.period_years = parseInt(this.queryParams.get('period_years'))
      }

      if (this.queryParams.get('accept_bad_credit') === 'true') {
        this.filters.accept_bad_credit = true
      }

      if (this.queryParams.get('no_uc') === 'true') {
        this.filters.no_uc = true
      }

      (async () => {

        const LoanData = await this.dynamicDataImport()

        this.searchResults = await this.getSearchResults(LoanData)

        await this.setInterests()

        await this.setLoanTotals()

        this.loadingComplete = true

      })();

    } catch (error) {
      console.error('LoanService error:')
      console.error(error)
    }
    
  },

  computed: {
    filteredResults ()
    {
      if (this.providerData.length >= 1) {
        return this.searchResults
      } else {
        return this.searchResults
              .filter(this.filterByAmount)
              .filter(this.filterByBadCredit)
              .filter(this.filterByNoUC)
              .filter(this.filterByPeriod)
              .filter(this.filterByBank)
              .slice(0, this.filters.limit)
      }      
    },

    filteredResultsCount ()
    {
      return this.filteredResults.length
    }
  },

  methods: {

    formatCurrency: numbersUtils.formatCurrency,
    formatPercent: numbersUtils.formatPercent,

    setFilterAmount (amount)
    {
      this.filters.loan_amount = parseInt(amount)
    },

    setFilterBadCredit (bool)
    {
      this.filters.accept_bad_credit = bool
    },

    setFilterNoUC (bool)
    {
      this.filters.no_uc = bool
    },

    filterByAmount (results)
    {
      return results.amount_minimum <= this.filters.loan_amount && results.amount_maximum >= this.filters.loan_amount
    },

    filterByBadCredit (results)
    {
      if (this.filters.accept_bad_credit) {
        return results.bad_credit === true
      } else {
        return results
      }
    },

    filterByNoUC (results)
    {
      if (this.filters.no_uc) {
        return results.providerData.credit_report_provider !== 'uc'
      } else {
        return results
      }
    },

    filterByPeriod (results)
    {
      if (this.filters.period_years >= 1) {
        return ((results.period_years_minimum <= this.filters.period_years && results.period_years_maximum >= this.filters.period_years) || (results.period_years_minimum === undefined))
      } if (this.filters.period_years < 1) {
        return results.period_years_minimum === undefined
      }
      
    },

    filterByBank (results)
    {
        if (this.filters.direct_payment_bank === 'all') {
          return results
        } else {
          if (results.direct_payment) {
            return results.direct_payment.includes(this.filters.direct_payment_bank)
          }
        }
      
    },

    loadMoreResults (amount)
    {
      this.filters.limit = this.filters.limit + amount
    },

    async setLoanTotals ()
    {
      await this.setLoanPeriods()
      await this.setLoanAmounts()
      await this.setLenderAmount()
    },

    async setLoanAmounts ()
    {
      this.loanTotals.amount_maximum = Math.max(...this.searchResults.map(o => o.amount_maximum), 1000)
      this.loanTotals.amount_minimum = Math.min(...this.searchResults.map(o => o.amount_minimum), this.loanTotals.amount_maximum)
    },

    async setLenderAmount ()
    {
      this.loanTotals.amount_of_lenders = this.searchResults.length
    },

    async setInterests ()
    {
      const interests1 = this.searchResults.filter(o => o.interest !== undefined).map(o => o.interest)
      const interests2 = this.searchResults.filter(o => o.interest_minimum !== undefined).map(o => o.interest_minimum)
      const interests3 = this.searchResults.filter(o => o.interest_maximum !== undefined).map(o => o.interest_maximum)

      const interests = [].concat(interests1, interests2, interests3)

      this.loanTotals.interest_minimum = Math.min(...interests, 1000)
      this.loanTotals.interest_maximum = Math.max(...interests, 1)
    },

    async setLoanPeriods ()
    {
      const periods_minimum = this.searchResults.filter(o => o.period_years_minimum !== undefined).map(o => o.period_years_minimum)
      const periods_maximum = this.searchResults.filter(o => o.period_years_maximum !== undefined).map(o => o.period_years_maximum)

      if (periods_minimum.length >= 1 && periods_maximum.length >= 1) {
        this.loanTotals.period_years_minimum = Math.min(...periods_minimum, 1000)
        this.loanTotals.period_years_maximum = Math.max(...periods_maximum, 1)
        this.show_period_select = true
      }
      
    },

    setProviderIdsFromParams (ids)
    {
      // Check if param is a single number or number as string, or multiple ids comma separated
      let providerIds = []
      const providerId = Number(ids)

      if (Number.isInteger(providerId) && providerId >= 1) {
        // Single provider id
        providerIds.push(ids)
      } else {
        // Multiple providers
        providerIds = ids.split(',')
      }

      return providerIds
    },

    getProviderDataById (id) 
    {
      const providerId = parseInt(id)

      const providerData = this.Providers.find(x => x.id === providerId)

      if (!providerData) {
        return null
      }

      return providerData
    },

    async dynamicDataImport ()
    {
      const serviceType = this.widgetParams.service_type.toLowerCase()

      if (serviceType === 'loans') {

        this.show_direct_payment_select = true

        this.AccountCreditLoansData = await this.importDataFromJSON("account_credit_loans.json")
        this.PaydayLoansData = await this.importDataFromJSON("payday_loans.json")
        this.PersonalLoansData = await this.importDataFromJSON("personal_loans.json")
        this.BrokerLoansData = await this.importDataFromJSON("broker_loans.json")

      } else if (serviceType === 'payday_loans') {

        this.show_direct_payment_select = true

        this.AccountCreditLoansData = await this.importDataFromJSON("account_credit_loans.json")
        this.PaydayLoansData = await this.importDataFromJSON("payday_loans.json")

      } else if (serviceType === 'account_credit_loans') {

        this.show_direct_payment_select = true

        this.AccountCreditLoansData = await this.importDataFromJSON("account_credit_loans.json")

      } else if (serviceType === 'personal_loans') {

        this.PersonalLoansData = await this.importDataFromJSON("personal_loans.json")

      } else if (serviceType === 'broker_loans') {

        this.BrokerLoansData = await this.importDataFromJSON("broker_loans.json")
      }

      const LoanData = [].concat(this.AccountCreditLoansData, this.PaydayLoansData, this.PersonalLoansData, this.BrokerLoansData)

      await LoanData.forEach(async loan => {
        loan.providerData = this.getProviderDataById(loan.provider)
      })

      return LoanData
    },

    async importDataFromJSON (filename)
    {
      const data = await import("@/assets/data/" + filename)
        .then(({default: json}) => {
            return json
        })

      return data
    },

    async getSearchResults (LoanData)
    {
      if (this.providerData.length >= 1) {
        let result = []
        await this.providerData.forEach(async provider => {
          result.push(await LoanData.find(x => x.provider === provider.id))
        })
        return result
      } else {

        // Sort by sort order
        const sortedData = sortBy(LoanData, [function(o) { return o.providerData.order }])
        return sortedData
      }
    },

    buildOutgoingLink (providerId, serviceType = 'default')
    {
      const data = {
        provider_id: providerId,
        service_type: serviceType
      }

      const buff = new Buffer.from(JSON.stringify(data))
      const link = '/r/?d=' + buff.toString('base64')

      return link
    },

    generateExampleCalculation (exampleCalculationData)
    {
      const exampleCalculationString = 'Ett lån på ' + this.formatCurrency(exampleCalculationData.amount) + ' kr till ' + this.formatPercent(exampleCalculationData.interest) + '% ränta med en återbetalningstid på ' + exampleCalculationData.period + ' månader, med 1 betalning per månad om ' + this.formatCurrency(exampleCalculationData.monthly_average_payment_amount) + ' kr och ' + this.formatCurrency(exampleCalculationData.setup_fee) + ' kr i uppläggningsavgift samt ' + this.formatCurrency(exampleCalculationData.invoice_fee) + ' kr i aviavgift ger en effektiv ränta på totalt ' + this.formatPercent(exampleCalculationData.effective_interest) + '%. Totalt att återbetala blir ' + this.formatCurrency(exampleCalculationData.total_to_repay) + ' kr.'

      return exampleCalculationString
    },
  }
}
</script>

<style>

</style>
