<template>
  <div id="compare-widget">
  <v-app>
    <v-main>
      <v-container fluid>
        <!-- <p>Params: {{ widgetParams }}</p> -->
        <LoanService v-if="showComparison" :widgetParams="widgetParams" :queryParams="queryParams" />
      </v-container>
    </v-main>
  </v-app>
  </div>
</template>

<script>
import LoanService from './components/LoanService.vue'

export default {
  name: 'compare-widget',

  components: {
    LoanService
  },

  props: ['params'],

  data: () => ({
    queryParams: null,
    widgetParams: {
      slider: false
    },
    showComparison: false,
    acceptedServiceTypes: [
        'loans',
        'personal_loans',
        'broker_loans',
        'account_credit_loans',
        'payday_loans'
      ]
  }),

  beforeMount () {

    try {

      const queryString = window.location.search
      this.queryParams = new URLSearchParams(queryString)

      if (this.params.length >= 1) {
        Object.assign(this.widgetParams, JSON.parse(this.params))
      }

      // Check accepted service type
      if (this.acceptedServiceTypes.includes(this.widgetParams.service_type)) {
        this.showComparison = true
      }
      
    } catch (error) {
      console.error('App error:')
      console.error(error)
    }   
    
  },

  methods: {
  }
}
</script>

<style>

#app .v-application--wrap {
  min-height: 0 !important;
}

</style>
