<template>
  <v-container v-if="filteredResults.length >= 1" class="ma-0 pa-3" fluid>
    <div v-for="(result, index) in filteredResults" :key="`result-${index}`">
      <PersonalLoansResultSingle v-if="result.service_type === 'personal_loans'" :searchResult="result" />
      <AccountCreditLoansResultSingle v-else-if="result.service_type === 'account_credit_loans'" :searchResult="result" />
      <PaydayLoansResultSingle v-else-if="result.service_type === 'payday_loans'" :searchResult="result" />
      <BrokerLoansResultSingle v-else-if="result.service_type === 'broker_loans'" :searchResult="result" />
      <NoServiceTypeResultSingle v-else />
    </div>
    <v-row>
      <v-col v-if="$parent.filters.limit < searchResults.length && filteredResults.length >= searchResultsLimit" class="mb-5 text-center">
        <v-btn v-on:click="$parent.loadMoreResults(searchResultsLoadMoreLimit)" type="submit" color="orange darken-2" dark large class="w-100 p-3 font-weight-bold apply-button">
          Visa fler
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="resultHasPaydayLoans" class="p-5">
      <v-col sm="12" md="12" lg="1" cols="12" class="mb-3 text-center" style="align-items: center; justify-content: center; display: flex; flex-direction: column;">
        <img alt="Högkostnadskredit" src="@/assets/hogkostnad-symbol.png" style="width: 50px" />
      </v-col>
      <v-col sm="12" md="12" lg="11" cols="12" style="font-size: 13px;">
        <strong>Det här är en högkostnadskredit</strong>
        <p>Om du inte kan betala tillbaka hela skulden riskerar du en betalningsanmärkning. För stöd, vänd dig till budget- och skuldrådgivningen i din kommun. Kontaktuppgifter finns på hallåkonsument.se.</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Providers from '@/assets/data/providers.json'
// import AccountCreditLoansResultList from './AccountCreditLoansResultList.vue'
import AccountCreditLoansResultSingle from './AccountCreditLoansResultSingle.vue'
import PaydayLoansResultSingle from './PaydayLoansResultSingle.vue'
import PersonalLoansResultSingle from './PersonalLoansResultSingle.vue'
import BrokerLoansResultSingle from './BrokerLoansResultSingle.vue'
import NoServiceTypeResultSingle from './NoServiceTypeResultSingle.vue'

export default {
  name: 'LoanResult',

  components: {
    AccountCreditLoansResultSingle,
    PaydayLoansResultSingle,
    PersonalLoansResultSingle,
    BrokerLoansResultSingle,
    NoServiceTypeResultSingle
  },

  props: {
    searchResults: { type: Array, required: true },
    filteredResults: { type: Array, required: true },
    widgetParams: { type: Object, required: true },
  },

  data: () => ({
    Providers,
    providersData: [],
    searchResultsLimit: 5,
    searchResultsLoadMoreLimit: 5
  }),

  beforeMount () {
    try {

      if (this.widgetParams.provider_id) {
        const providerIds = this.setProviderIdsFromParams(this.widgetParams.provider_id)

        providerIds.forEach(id => {
          this.providersData.push(this.getProviderDataById(id))
        })
      } else {
        this.providersData = Providers
      }
      
    } catch (error) {
      console.error('LoanResult error:')
      console.error(error)
    }
    
  },

  computed: {
    resultHasPaydayLoans ()
    {
      const result = this.filteredResults.find(x => x.service_type === 'account_credit_loans')

      if (result) {
        return true
      } else {
        return false
      }
    }
  },

  methods: {

    setProviderIdsFromParams (ids)
    {
      // Check if param is a single number or number as string, or multiple ids comma separated
      let providerIds = []
      const providerId = Number(ids)

      if (Number.isInteger(providerId) && providerId >= 1) {
        // Single provider id
        providerIds.push(ids)
      } else {
        // Multiple providers
        providerIds = ids.split(',')
      }

      return providerIds
    },

    getProviderDataById (id)
    {
      const providerId = parseInt(id)

      const providerData = this.Providers.find(x => x.id === providerId)

      if (!providerData) {
        return null
      }

      return providerData
    }
  }

}
</script>

<style>
  .box-shadow {
    box-shadow: 0 .10rem .5rem rgba(39,52,68,.3) !important;
  }

  .example {
    color: #808080;
  }

  .results-box {
    background: #ffffff;
  }

  .results-box-column {
    color: #000;
    font-size: 110%;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .results-box-column-heading {
    color: #737373;
    text-transform: uppercase;
    display: block;
    font-size: 80%;
    font-weight: bold;
    line-height: 32px;
  }

  .results-box-column-heading .v-icon {
    line-height: 32px;
  }

  .results-box-usp {
    border-top: solid 1px #d0d0d0;
  }

  .results-box-usp ul {
    display: table;
    margin: 0 auto !important;
    padding-left: 0 !important;
  }

  .results-box-usp ul li {
    list-style: none;
    display: list-item;
    float: left;
    padding: 10px !important;
    font-size: 90%;
    margin: 5px 0 5px 0 !important;
  }

  .more-information {
    font-size: 80%;
  }

  .more-information span {
    font-weight: bold;
  }

  .more-information p {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }

  .v-application .v-expansion-panel-header {
    text-align: center !important;
    display: block;
  }

  .v-application .v-expansion-panel-header__icon {
    margin-left: unset !important;
  }

  .accordion-header i {
    font-size: 15px !important;
    margin-left: 10px;
  }

  .v-chip__content {
    margin: 0 !important;
    font-size: 90%;
  }

  .apply-button {
    color: #ffffff !important;
    font-size: 110% !important;
    height: 60px !important;
    width: 200px !important;
  }

  .more-info-button {
    color: #000000 !important;
    font-size: 100% !important;
    height: 40px !important;
    width: 200px !important;
  }

  .apply-button a, .review-link a:visited, .review-link a:hover, .review-link a:active {
    color: #ffffff !important;
    text-decoration: none;
  }

  .review-link a, .review-link a:visited, .review-link a:hover, .review-link a:active {
    font-size: 90%;
    color: #25b5b4 !important;
    text-decoration: none;
  }

  .badge {
    margin: -18px 0 0 -12px;
    padding: 0 10px 0 10px;
    font-size: 90%;
    font-weight: bold;
    border-radius: 0px 0px 5px 0px !important;
  }

  .badge-broker {
    margin: -12px -12px 0 0;
    padding: 0 10px 0 10px;
    font-size: 90%;
    font-weight: bold;
    float: right;
    border-radius: 0px 0px 0px 5px !important;
  }
</style>