import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false

/*
 * This code gets the widget properties passed by the HTML page.
 * This allows the widget to be modified on runtime with arguments.
 * The data is passed down using propsData and caught in App.vue
 * Example: <div id='compare-widget' params='{ "provider_id": 1, "loan_amount": "10000" }'></div>
 * Supports multiple widgets with a unique identifier for multiple mounting. id='compare-widget-123'
 */

// Match all divs that begin with the ID "compare-widget" to support multiple widgets
const allWidgetElements = document.querySelectorAll('[id^=compare-widget-app')

for (let i = 0; i < allWidgetElements.length; i++) {
  let params = allWidgetElements[i].getAttribute('params')

  // Modified mounting to allow passing params into App.vue
  const AppRoot = Vue.extend(App)
  new AppRoot({
    vuetify,
    el: allWidgetElements[i],
    propsData: { params },
  }).$mount(allWidgetElements[i])
}
